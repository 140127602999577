import React from 'react';
import { Nav, Navbar } from 'react-bootstrap'
import logo from '../images/logo.jpg' 

const styleNavbarText = {
    fontSize: '1.7rem', 
    fontStyle: 'italic', 
    textDecoration: 'underline',
    color: '#fff',
    marginRight: '2rem'
}

const styleNavbarLink1 = {
    fontSize: '1.2rem', 
}

const styleNavbarLink2 = {
    fontSize: '1.2rem', 
}

const styleNavbar = {
    padding: "0", 
}

const styleLogo = {
    padding: '0',
    marginRight: '1rem'
}

export function NaviBar() {
    return (
        <>

            <Navbar style={styleNavbar} collapseOnSelect expand='lg' bg='secondary' variant="dark">
                <Navbar.Brand  style={styleLogo}>
                    <img src={logo} alt='Icon' width='100' height='75' />
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav>
                        <Nav.Link style={styleNavbarLink1} href='http://BUH4BOSS.RU'>Главная</Nav.Link>
                        <Nav.Link style={styleNavbarLink2} href='#services'>Услуги</Nav.Link>
                        <Nav.Link style={styleNavbarLink2} href='#about'>О нас</Nav.Link>
                    </Nav>
                    <Nav style={{paddingRight: "4rem"}} className='ms-auto'>
                        <Nav.Link style={styleNavbarLink1} href='#contacts'>Контакты</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
        </Navbar>       
        </>

    )
};