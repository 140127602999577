import React from "react";
import './modalButton.css'
import { Telephone } from 'react-bootstrap-icons';

export function ModalButton({click}) {
    return (
        <div className="phone-button" onClick={()=>click(true)}>
            <a href="#" title="Обратный звонок">
            <Telephone size={25} />
            </a>
        </div>
    )
}