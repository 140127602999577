import React from 'react';
import instagram from '../images/social/instagram.jpg'
import whatsapp from '../images/social/whatsapp.jpg'
import telegram from '../images/social/telegram.jpg'

const socialLink = [
    {
        socialName: 'Instagram',
        alt: 'Inst',
        imageSrc: instagram, 
        link: 'https://www.instagram.com/'
    },
    {
        socialName: 'WhatsApp',
        alt: 'WApp',
        imageSrc: whatsapp,
        link: 'https://www.whatsapp.com/'
    },
    {
        socialName: 'Telegram',
        alt: 'Tg',
        imageSrc: telegram,
        link: 'https://web.telegram.org'
    }
]

const styleCopyright = {
    fontSize: '0.65rem', 
    display: 'flex',
    alignItems: 'center'
}

const styleFooter = {
    margin: '0 3rem',
    marginTop: '1rem',
    padding: '2rem 0',
    borderTop: '1px #bdbdbd solid',
    display: 'flex',
    justifyContent: 'space-between'
}

const styleSocialList = {
    listStyle: 'None',
}

const styleSocialListElement = {
    //display: 'inline',
    float: 'left',
    padding: '0 0.5rem'
}

function CreateSocial(props) {
    const socialDict = props.links
    return (
        <ul style={styleSocialList}>
            {
                socialDict.map((val, ind) => <li key={ind} style={styleSocialListElement}><a href={val.link}><img src={val.imageSrc} alt={val.alt}></img></a></li>)
            }
        </ul>
    )
}

export function Footer() {

    return (
        <>
            <footer style={styleFooter}>
                <div style={styleCopyright}><span>© B4b Inc., 2022 Все права защищены.</span></div>
               {/* <CreateSocial links={socialLink}/> */}
            </footer>
        </>
    )
} 

