import { NaviBar } from './Components/navbar'
import { Content } from './Components/content'
import { Footer } from './Components/footer'
import { Modal } from './Components/Modal/modal'
import { ModalButton } from './Components/Modal/modalButton'
import React from 'react'

function App() {
  const [modalActive, setModalActive] = React.useState(false)
  return (
    <>      
      <Modal active = {modalActive} setActive={setModalActive}/>
      <NaviBar/>
      <Content/>
      <Footer/>   
      <ModalButton click={setModalActive}></ModalButton>   
    </>
    
  );
}

export default App;
