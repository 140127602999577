import React from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap';
import title from '../images/title.jpg'
import { Alarm, PiggyBank, EmojiSunglasses, Activity, GraphDownArrow, PatchCheck } from 'react-bootstrap-icons';

const styleImage = {
    marginTop: '1rem',
}

const styleLi = {
  
    marginBottom: '1rem',
}

const styleText = {
    marginTop: '1rem',
    borderTop: '5px gray dotted',
    borderBottom: '5px gray dotted',
    fontSize: '1.5rem',
    padding: '1rem',
    textAlign: 'center',
    paddingBottom: '0',
    fontWeight: 'bold'   
}

const styleHeader = {
    marginTop: '1rem',
    fontSize: '2rem',
    padding: '1rem',
    textAlign: 'center',
    paddingBottom: '0', 
}

const styleInfoBlock = {
    border: '0.5px white solid',
    padding: '1rem',
    margin: '1rem',
    boxShadow: '1px 1px 20px #A8BDFF',
}

const styleInfoBlockHeader = {
    textAlign:'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    padding: '1rem',
    textDecoration: 'underline'
}

const styleContacts = {   
    margin: '4rem 0 7rem 0',
    padding: '0 2rem',
    textAlign: 'center'
}

const styleContactsHeader = {
    fontWeight: 'bold'
}

const styleHr = {
    margin: '3rem 25%',
	padding: '0',
	height: '0',
    width: '50%',
	border: 'none',
	boxShadow: '0 0 1px 2px #A8BDFF',
}

const styleMap = {
    border:'0', 
    margin: '2rem 0', 
    width:'80%', 
    height: '20rem'
}

const styleIcon = {
    fontSize: '2rem',
    margin: '0 1rem',
    paddingBottom: '0.2rem'
}


const styleContainerWork = {
    margin: '3rem auto',
    maxWidth: '80%'
}

const styleContainerAbout = {
    margin: '3rem auto',
    maxWidth: '75%'
}

const styleHeaderWork = {
    fontWeight: 'Bold'
}

const styleTextWork = {
    padding: '0.5rem 4rem'
}

const styleAboutBlock = {
    
    fontSize: '1.1rem'
}

const styleContentAboutText = {
    padding: '0 0', 

}

const styleListAbout = {
    display: 'flex'
}


function CreateContent(props) {
    let header = props.contentDict.header
    let contentList = props.contentDict.list
    return (
        <div style={styleInfoBlock}>
            <h3 style={styleInfoBlockHeader}>{header}</h3>
            <ul>
                {
                    contentList.map((val, ind) => {
                        return (<li style={styleLi} key={ind}>{val}</li>) 
                    })
                }             
            </ul>        
        </div>
    )
}

function CreateBlockWork(props) {
    let header = props.contentDict.header
    let contentText = props.contentDict.text
    let ContentImage = props.contentDict.image
    return (
        <div >
            <ContentImage style={styleIcon}/>
            <span style={styleHeaderWork}>{header}</span>
            <p style={styleTextWork}>{contentText}</p>     
        </div>
    )
}

const buhDict = {header:'Бухгалтерия', list: [ 'составление учетной политики',
    'постановка и ведение бухгалтерского и налогового учета',
    'подготовка и сдача отчетности (бухгалтерской, налоговой, кадровой, статистической, отчетности во внебюджетные фонды)',
    'ведение первичной документации',
    'электронный документооборот с контрагентами']}

const consultDict = {header: 'Консультирование', list: ['регистрация ИП и ООО (подбор системы налогообложения, кодов ОКВЭД)',
    'подача заявлений на патент',
    'ответы на требования налоговой инспекции, пенсионного фонда, ФСС',
    'консультационное сопровождение при прохождении налоговой проверки, а также проверок других контролирующих органов',
    'консультации по вопросам организации и ведения бухгалтерского и налогового учета',
    'подготовка и сдача 3-НДФЛ']}

const lawDict = {header:'Юридическая помощь', list: ['подготовка и анализ договоров',
    'консультации по вопросам банкротства',
    'ликвидация юридических лиц',
    'подготовка исковых заявлений',
    'подготовка мотивированных возражений по актам налоговых проверок',
    'консультации по вопросам гражданского законодательства']}

const textWork1 = {header:'Больше свободы', text: 'Освобождаем вас от бухгалтерской и налоговой рутины, что дает возможность сосредоточиться на развитии бизнеса',
    image: Alarm
}
const textWork2 = {header:'Меньше затрат', text: 'Экономия затрат на организацию и ведения бухгалтерского учета',
    image: PiggyBank
}
const textWork3 = {header:'Меньше рисков', text: 'Команда специалистов с опытом работы более 10-ти лет в вашем распоряжении',
    image: GraphDownArrow
}
const textWork4 = {header:'Больше спокойствия', text: 'Мы имеем практический опыт прохождения проверок контролирующих органов с положительным результатом для организации',
    image: EmojiSunglasses
}

const aboutMission = ['Помочь каждому клиенту решить даже самые нестандартные проблемы в бухгалтерской, налоговой и юридической областях.',
    'Мы стремимся к тому, чтобы наши знания и опыт способствовали росту вашего благосостояния.']

const aboutHistory = ['Buh4boss – проект, который объединил профессиональных бухгалтеров, аудиторов и специалистов по налогообложению, чтобы помочь вашему бизнесу стабильно развиваться в стремительно меняющейся экономике.',
    'Команда Buh4boss состоит из экспертов с опытом работы более 10-ти лет, которые регулярно проходят программы повышения профессиональной квалификации.', 
    'Наши специалисты консультируют и ведут бухгалтерский учет для компаний, осуществляющих деятельность в сферах: торговля, производство, а также различные виды услуг (включая средние и крупные предприятия).', 
    'Сотрудничество с командой Buh4boss избавит вас от необходимости решать рутинные бухгалтерские и налоговые вопросы и позволит сосредоточиться на росте вашего бизнеса.']

export function Content() {

    return (
        <>
            <Image src={title} thumbnail className='mx-auto d-block' style={styleImage} />
            <div style={styleText}>
                <p>Ваш помощник в бухгалтерской, налоговой и юридической областях</p>
            </div>
            <div style={styleHeader}>
                <p id='services'>Виды услуг:</p>
            </div>
            <Container fluid style={{maxWidth: '80%'}}>
                <Row className="d-flex justify-content-center">
                    <Col>
                        <CreateContent contentDict={buhDict} />
                    </Col>
                    <Col>
                        <CreateContent contentDict={consultDict} />
                    </Col>
                    <Col>
                        <CreateContent contentDict={lawDict} />
                    </Col>
                </Row>
            </Container>
            <hr style={styleHr}/>
            <div style={styleHeader}>
                <p id='work'>Что дает сотрудничество с нами?</p>
            </div>
            <Container fluid style={styleContainerWork}>
                <Row className="d-flex justify-content-center">
                    <Col>
                        <CreateBlockWork contentDict={textWork1} />
                    </Col>
                    <Col>
                        <CreateBlockWork contentDict={textWork2} />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center" style={{marginTop: '3rem'}}>
                    <Col>
                        <CreateBlockWork contentDict={textWork3} />
                    </Col>
                    <Col>
                        <CreateBlockWork contentDict={textWork4} />
                    </Col>
                </Row>
            </Container>
            <hr style={styleHr}/>
            <div style={styleHeader}>
                <p id='about'>Наша миссия</p>
            </div>
            <Container fluid style={styleContainerAbout}>
                <Row className="d-flex justify-content-center">
                    <Col style={styleAboutBlock}>
                        {aboutMission.map(el => (<ul style={{listStyle: 'none'}}>
                            <li style={styleListAbout}>
                                <div></div><p style={styleContentAboutText}>{el}</p></li></ul>))}
                    </Col>                    
                </Row>
            </Container>
            <hr style={styleHr}/>
            <div style={styleHeader}>
                <p>Наша история</p>
            </div>
            <Container fluid style={styleContainerAbout}>
                <Row className="d-flex justify-content-center">
                    <Col style={styleAboutBlock}>
                        {aboutHistory.map(el => (<ul style={{listStyle: 'none'}}>
                            <li style={styleListAbout}>
                                <div></div><p style={styleContentAboutText}>{el}</p></li></ul>))}
                    </Col>                   
                </Row>
            </Container>
            <hr style={styleHr}/>
            <div >
                <div style={styleHeader}>
                    <p id='contacts'>Наши контакты:</p>
                </div>
                    
                <div style={styleContacts}>       

                    <address><span style={styleContactsHeader}>Телефон: </span>+7 909 383-25-28</address>
                    <address><span style={styleContactsHeader}>E-mail: </span><a href='mailto:buh4boss@mail.ru' style={{color:'black'}}>buh4boss@mail.ru</a></address>
                    <address><span style={styleContactsHeader}>Индивидуальный предприниматель: </span>Устинова Марина Сергеевна</address>
                    <address><span style={styleContactsHeader}>ИНН: </span>344403492229</address>
                </div>

             
            </div>           
        </>
    )
}