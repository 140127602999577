import React from "react";
import './modal.css'
import { Button, Form, InputGroup } from "react-bootstrap";
import axios from 'axios'


export function Modal({active, setActive}) {

    
    const [dataForm, setDataForm] = React.useState({phone:'', text:''})

    let name, value
    const handleInput = e => {
        name = e.target.name
        value = e.target.value
        setDataForm({...dataForm, [name]:value})       
    }

    const saveData = (e) => {  
        const sendingData = {
            'service_id': 'service_4uihqmu',
            template_id: 'template_4gr09xb',
            user_id: 'zhtII0E18xv-2CIbE',
            template_params: {
                'phone': dataForm.phone,
                'text': dataForm.text
            }
        }
        setActive(false)
        console.log(sendingData)
        axios.post(`https://api.emailjs.com/api/v1.0/email/send`,  
            sendingData)
            .then(res => {
        console.log(res);
        console.log(res.data);
      })
    }

      
    return (
        <div className={active ? 'modal active' : 'modal'} onClick={()=>setActive(false)}>
            <div className="content" onClick={e => e.stopPropagation()}>
                <h4>У вас есть вопросы?</h4>
                <p>Укажите номер телефона и мы перезвоним вам в самое ближайшее время.</p>
                <form method="GET" action="#">
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Ваш телефон
                        </InputGroup.Text>
                        <Form.Control aria-label="Default" aria-describedby="inputGroup-sizing-default" pattern="[7-8]{1}[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}" name='phone' 
                            minLength="6" maxLength="12" placeholder="79111234567" onChange={handleInput} value={dataForm.phone}/>
                    </InputGroup>
                    <p className="question-form">или задайте свой вопрос ниже</p>
                    <InputGroup>
                        <Form.Control as="textarea" aria-label="With textarea" rows='5' name='text' value={dataForm.text} onChange={handleInput}/>
                    </InputGroup>
                    <Button variant="outline-dark" size="sm" className="button-form " type="button" onClick={saveData}>Отправить</Button>
                </form>
            </div>
        </div>
    )
}